import '../styles/styles.scss';

// Splide Default theme
import '@splidejs/splide/css';
import Splide from '@splidejs/splide';
window.Splide = Splide

import Alpine from 'alpinejs'
import collapse from '@alpinejs/collapse'
import morph from '@alpinejs/morph'

window.Alpine = Alpine
Alpine.plugin([collapse, morph])
Alpine.start()






// import '../../node_modules/prettier-plugin-css-order/src/main.mjs';
// import '../../node_modules/prettier-plugin-tailwindcss/dist/index.mjs';


// import App from './app';


import './general';
// import './swiper';


// App.init();
//
